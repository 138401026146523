<template>
  <div>
    <v-autocomplete
      :label="$t('etag')"
      :disabled="op === 'update'"
      class="mb-5"
      outlined
      hide-details
      v-model="value.tagID"
      :items="etags"
      :filter="etagFilter"
      item-text="tagID"
      item-value="tagID"
      :no-data-text="$t('noEtagsAvailableAtMoment')"
      v-if="!bulk"
    >
      <template v-slot:selection="{ item }">
        <div class="my-1">
          <div :class="item.description ? 'pb-1' : ''">
            {{ item.tagID }}
          </div>
          <div style="font-size: 12px;">
            {{ item.description }}
          </div>
        </div>
      </template>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-html="data.item.tagID" />
          <v-list-item-subtitle v-html="data.item.description" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-select
      :label="$t('channel')"
      class="mb-5"
      outlined
      hide-details
      :items="channels"
      v-model="channel"
      item-text="label"
      item-value="uuid"
    />
    <v-select
      :label="$t('categories')"
      class="mb-5"
      multiple
      outlined
      hide-details
      v-model="categories"
      :items="categoriesAvailable"
      item-text="name"
      item-value="uuid"
    />
    <v-select
      :label="$t('paymentInstance')"
      class="mb-5"
      outlined
      hide-details
      v-model="paymentInstance"
      :items="paymentMethodInstances"
      :item-text="paymentMethodInstanceTitleCallback"
      item-value="uuid"
    />
    <v-expansion-panels>
      <v-expansion-panel id="etag-settings">
        <v-expansion-panel-header>{{ $t('generalDisplaySettings') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-switch
            outlined
            hide-details
            :label="$t('singlePageMenu')"
            v-model="singlePageCategories"
          />
          <v-switch
            outlined
            hide-details
            :label="$t('showLandingPage')"
            v-model="landingPage"
          />
          <v-switch
            outlined
            hide-details
            :label="$t('showWaitingTime')"
            v-model="showWaitingTime"
          />
          <v-switch
            outlined
            hide-details
            :label="$t('hideListPictures')"
            v-model="hideListPictures"
          />
          <v-switch
            hide-details
            :label="$t('useLocationOpeningHours')"
            v-model="followLocationOpeningHours"
          />
          <v-select
            outlined
            hide-details
            :label="$t('defaultLanguage')"
            v-model="defaultLanguage"
            :items="languages"
            class="pt-4"
          >
            <template v-slot:item="{ item }">
              <span>{{ item.text }} <span class="caption">{{ item.value }}</span></span>
            </template>
          </v-select>
          <v-select
            outlined
            hide-details
            :label="$t('currencyThousandsFormatRule')"
            class="pt-4"
            v-model="currencyFormatRule"
            :items="currencyRuleTypes"
            item-value="value"
          >
            <template v-slot:selection="{ item }">
              <span>{{ item.text }} <span class="caption font-italic">({{ item.example }})</span></span>
            </template>
            <template v-slot:item="{ item }">
              <span>{{ item.text }} <span class="caption font-italic">({{ item.example }})</span></span>
            </template>
          </v-select>
          <v-switch
            v-if="userIsAdmin"
            hide-details
            class="mb-3"
            :label="$t('enableReservationMode')"
            v-model="enableReservationMode"
          />
          <v-select
            v-if="userIsAdmin && enableReservationMode === true"
            outlined
            hide-details
            :label="$t('widgetProfile')"
            v-model="widgetUUID"
            :no-data-text="$t('noReservationWidgetsFoundForLocation')"
            :items="reservationWidgets"
            item-text="label"
            item-value="uuid"
            :disabled="gettingReservationWidgets"
            :loading="gettingReservationWidgets"
          />
          <v-switch
            v-if="userIsAdmin && enableReservationMode === true"
            hide-details
            class="mb-3"
            :label="$t('turnOnTakeAwayTextForRoomService')"
            v-model="roomServiceTakeawayText"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel id="etag-categories">
        <v-expansion-panel-header>{{ $t('categoryDisplaySettings') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-switch
            outlined
            hide-details
            :label="$t('showSubCategories')"
            v-model="showSubCategoryMenu"
            :disabled="singlePageCategories"
          />
          <v-switch
            outlined
            :label="$t('hideClosedCategories')"
            v-model="hideClosedCategories"
            persistent-hint
            :hint="$t('hideCategoriesHint')"
          />
          <v-switch
            outlined
            hide-details
            :label="$t('showTheOtherCategory')"
            v-model="showOthersCategory"
            :disabled="singlePageCategories"
          />
          <v-row
            align="start"
            style="border: 1px solid #9e9e9e; border-radius: 3px;"
            class="my-4 mx-1 text--secondary"
          >
            <v-col cols="2">
              <v-icon
                small
                style="vertical-align: text-top"
              >
                mdi-information
              </v-icon>
            </v-col>
            <v-col
              class="caption text-justify"
              cols="10"
            >
              {{ $t('selectingCategoriesToExcludeWillMakeIt') }}
            </v-col>
          </v-row>
          <v-autocomplete
            :label="$t('excludeCategories')"
            multiple
            outlined
            small-chips
            persistent-hint
            :hint="$t('excludeCategoriesHint')"
            v-model="excludeTheseCategories"
            :items="categoriesAvailable"
            item-text="name"
            item-value="uuid"
            :no-data-text="$t('couldNotFindCategory')"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel id="etag-items">
        <v-expansion-panel-header>{{ $t('itemDisplaySettings') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            align="start"
            class="text--secondary"
          >
            <v-col
              cols="12"
              class="py-0"
            >
              <v-alert
                type="info"
                outlined
                class="caption mb-0"
              >
                {{ $t('toSeeItemsInCategoriesTheItemsMustFirstBeMarked') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-switch
            outlined
            hide-details
            :label="$t('disableItemsDetailsPopup')"
            v-model="hideItemDetails"
            class="my-4"
          />
          <v-autocomplete
            :label="$t('exclude')"
            multiple
            outlined
            small-chips
            persistent-hint
            :hint="$t('itemsInCategoriesSelectedWillNotBeDisplayed')"
            v-model="excludeItemsInTheseCategories"
            :items="categoriesWithoutIncludedCategories"
            item-text="name"
            item-value="uuid"
            :no-data-text="$t('couldNotFindCategory')"
          />
          <v-autocomplete
            :label="$t('include')"
            multiple
            outlined
            small-chips
            persistent-hint
            :hint="$t('onlyItemsInCategoriesHereWillBeDisplayed')"
            v-model="includeItemsInTheseCategories"
            :items="categoriesWithoutExcludedCategories"
            item-text="name"
            item-value="uuid"
            :no-data-text="$t('couldNotFindCategory')"
          />
          <v-switch
            :label="$t('limitOpeningHoursCheck')"
            persistent-hint
            :hint="$t('enablingThisWillLimitOpeningHourCheck')"
            v-model="limitItemCategoryOpeningHourCheck"
          />
          <v-switch
            :label="$t('hideBlockedItems')"
            persistent-hint
            :hint="$t('hideBlockedItemsHint')"
            v-model="hideBlockedItems"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel id="etag-variations">
        <v-expansion-panel-header>{{ $t('variationsDisplaySettings') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-switch
            outlined
            hide-details
            :label="$t('disableVariationsSelection')"
            v-model="disableVariationSelection"
          />
          <v-switch
            outlined
            hide-details
            :label="$t('showAllVariationsSelectionsOnSinglePage')"
            v-model="singlePageVariations"
            :disabled="disableVariationSelection"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel id="etag-cart-settings">
        <v-expansion-panel-header>
          {{ $t('cartSettings') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-autocomplete
            outlined
            :label="$t('additionalMandatoryCartItems')"
            v-model="additionalCartItems"
            :items="items"
            item-text="title"
            item-value="uuid"
            multiple
            persistent-hint
            :hint="$t('additionalMandatoryCartItemsHint')"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel id="etag-custom-colors">
        <v-expansion-panel-header>{{ $t('customColorsDisplaySettings') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <ColorSelect
            :label="$t('primaryColor')"
            v-model="primaryColor"
          />
          <ColorSelect
            :label="$t('accentColor')"
            v-model="accentColor"
          />
          <v-btn
            block
            @click="resetColors"
            outlined
          >
            {{ $t('resetColors') }}
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel id="etag-advanced-settings">
        <v-expansion-panel-header>{{ $t('advancedSettings') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-switch
            persistent-hint
            :hint="$t('This setting is a manual handling of whether Apple Pay is allowed to be shown in the eTag, regardless of whether there is capability of Apple Pay or not.')"
            v-model="handleApplePayManualSetting"
            :label="$t('Apple Pay Manually Disabled')"
            class="pb-3"
          />
          <v-switch
            persistent-hint
            :hint="$t('attentionExperimentalFeature')"
            v-model="eventsMode"
            :label="$t('eventsMode')"
            class="pb-3"
          />
          <v-text-field
            outlined
            persistent-hint
            :hint="$t('attentionExperimentalFeature')"
            v-model="customURL"
            :label="$t('customURL')"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ColorSelect from "@/components/common/ColorSelect"

export default {
  name: 'EtagForm',
  components: {
    ColorSelect
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    bulk: {
      type: Boolean,
    },
    location: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    etagDialog: false,
    etagsDialog: false,
    etag: {},
    op: null,
    defaultEtag: {
      tagID: null,
      property: null
    },
    originalURL: null,
    customURL: null,
    notReady: false,
    reservationType: 'yess',
    gettingYessProfiles: false,
    gettingReservationWidgets: false
  }),
  methods: {
    paymentMethodInstanceTitleCallback(instance) {
      return instance.title + ' (' + instance.description + ')'
    },
    getReservationWidgets() {
      this.gettingReservationWidgets = true
      this.$store.dispatch('getReservationWidgets').finally(() => {
        this.gettingReservationWidgets = false
      })
    },
    resetColors() {
      this.primaryColor = null
      this.accentColor = null
    },
    isValidUrl(str) {
      if(typeof str !== 'undefined' && str !== null && str !== '') {
        let url

        try {
          url = new URL(str)
        } catch (e) {
          return false
        }

        if(/.+\..+\..+/gi.test(url.hostname)) {
          return url.protocol === "http:" || url.protocol === "https:"
        }
      }

      return false
    },
		etagFilter (item, queryText) {
			const searchText = queryText ? queryText.toLowerCase() : queryText
			const tagID = item.tagID ? item.tagID.toLowerCase() : item.tagID
			const description = item.description ? item.description.toLowerCase() : item.description

			if(description && tagID) {
				return description.indexOf(searchText) > -1 || tagID.indexOf(searchText) > -1
			}

    	if(description) {
				return description.indexOf(searchText) > -1
			}

			if(tagID) {
				return tagID.indexOf(searchText) > -1
			}

			return false
		}
  },
  watch: {
    reservationType() {
      this.slug = null
      this.widgetUUID = null
    },
    customURL(value) {
      if(value) {
        this.value.property = value
      } else {
        this.value.property = 'https://items.salescloud.is?organizationUUID=' + this.organizationUUID + '&l=' + this.location.uuid + '&unitID=' + this.value.unitID + '&singlePageCategories=true'
      }
    }
  },
  computed: {
    handleApplePayManualSetting: {
		get() {
			return this.propertyURL.searchParams.get('dap') === 'true'
		},
	    set(value) {
		    this.propertyURL.searchParams.set('dap', value)
		    this.value.property = this.propertyURL.toString()
	    }
    },
    userIsAdmin() {
      return this.$store.getters.userIsAdmin
    },
    reservationWidgets() {
      return this.$store.state.reservationWidgets
      // return this.$store.state.reservationWidgets.filter(widget => widget && widget.location && this.location && widget.location.uuid === this.location.uuid)
    },
    yessProfiles() {
      return this.$store.state.yessProfiles.filter(profile => profile && profile.organization && profile.organization.uuid === this.organizationUUID)
      // && profile.location && this.location && profile.location.uuid === this.location.uuid)
    },
    reservationTypes() {
      const types = []

      types.push({
        text: this.$t('widget'),
        value: 'widget'
      })

      return types
    },
    items() {
      return this.$store.getters.itemProducts
    },
    slug: {
      get() {
        return this.propertyURL.searchParams.get('slug')
      },
      set(value) {
        this.propertyURL.searchParams.delete('slug')

        if(value) {
          this.propertyURL.searchParams.set('slug', value)
        }

        this.value.property = this.propertyURL.toString()
      }
    },
    widgetUUID: {
      get() {
        return this.propertyURL.searchParams.get('widgetUUID')
      },
      set(value) {
        this.propertyURL.searchParams.delete('widgetUUID')

        if(value) {
          this.propertyURL.searchParams.set('widgetUUID', value)
        }

        this.value.property = this.propertyURL.toString()
      }
    },
    eventsMode: {
      get() {
        return this.propertyURL.searchParams.get('eventsMode') === 'true'
      },
      set(value) {
        this.propertyURL.searchParams.set('eventsMode', value)
        this.value.property = this.propertyURL.toString()
      }
    },
    enableReservationMode: {
      get() {
        return this.propertyURL.searchParams.get('enableReservationMode') === 'true'
      },
      set(value) {
        this.propertyURL.searchParams.delete('enableReservationMode')

        if(value === true) {
          this.propertyURL.searchParams.set('enableReservationMode', value)
        }

        if(value === false) {
          this.reservationLink = null
        }

        this.value.property = this.propertyURL.toString()
      }
    },
    reservationLink: {
      get() {
        return this.propertyURL.searchParams.get('reservationLink')
      },
      set(link) {
        this.propertyURL.searchParams.delete('reservationLink')


        if(this.isValidUrl(link)) {
          this.propertyURL.searchParams.set('reservationLink', link)
        }

        this.value.property = this.propertyURL.toString()
      }
    },
    disableEtagSelect() {

      if(this.value.tagID !== null && this.value.tagID !== undefined) {
        return true
      }

      return false
    },
    currencyRuleTypes() {
      return this.$store.getters.currencyRuleTypes
    },
    categoriesAvailable() {
      let categories = this.$store.state.categories
      if(Array.isArray(categories) && categories.length > 0) {
        return categories.filter(category => category && category.name && category.name.toLowerCase() !== 'etag' && category.name.toLowerCase() !== 'etags')
      } else {
        return categories
      }
    },
    categoriesWithoutExcludedCategories() {
      return this.categoriesAvailable.filter(category => !this.excludeItemsInTheseCategories.some(uuid => uuid === category.uuid))
    },
    categoriesWithoutIncludedCategories() {
      return this.categoriesAvailable.filter(category => !this.includeItemsInTheseCategories.some(uuid => uuid === category.uuid))
    },
    propertyURL() {
      return new URL(this.value.property)
    },
    additionalCartItems: {
      set(value) {
        this.propertyURL.searchParams.delete('addItem')
        if(Array.isArray(value) && value.length > 0) {
          for(let itemIndex = 0; itemIndex < value.length; itemIndex++) {
            this.propertyURL.searchParams.append('addItem', value[itemIndex])
          }
        }
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.getAll('addItem')
      }
    },
    channel: {
      set(value) {
        this.propertyURL.searchParams.set('channel', value)
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('channel')
      }
    },
    paymentInstance: {
      set(value) {
        this.propertyURL.searchParams.set('paymentInstanceUUID', value)
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('paymentInstanceUUID')
      }
    },
    landingPage: {
      set(value) {
        this.propertyURL.searchParams.set('landingPage', value)
        if(this.propertyURL.searchParams.get('singlePageCategories')) {
          this.propertyURL.searchParams.set('singlePageCategories', ((value === 'true' || value === true) ? 'false' : 'true'))
        }
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('landingPage') === 'true'
      }
    },
    showWaitingTime: {
      set(value) {
        this.propertyURL.searchParams.set('showWaitingTime', value)
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('showWaitingTime') === 'true'
      }
    },
    showSubCategoryMenu: {
      set(value) {
        this.propertyURL.searchParams.set('showSubCategoryMenu', value)
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('showSubCategoryMenu') === 'true'
      }
    },
    singlePageCategories: {
      set(value) {
        this.propertyURL.searchParams.set('singlePageCategories', value)
        if(this.propertyURL.searchParams.get('landingPage')) {
          this.propertyURL.searchParams.set('landingPage', ((value === 'true' || value === true) ? 'false' : 'true'))
        }
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('singlePageCategories') === 'true'
      }
    },
    hideListPictures: {
      set(value) {
        this.propertyURL.searchParams.set('hideListPictures', value)
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('hideListPictures') === 'true'
      }
    },
    followLocationOpeningHours: {
      set(value) {
        this.propertyURL.searchParams.set('followLocationOpeningHours', value)
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('followLocationOpeningHours') !== 'false'
      }
    },
    hideItemDetails: {
      set(value) {
        this.propertyURL.searchParams.set('hideItemDetails', value)
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('hideItemDetails') === 'true'
      }
    },
    currencyFormatRule: {
      set(value) {
        this.propertyURL.searchParams.set('currencyFormatRule', value)
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('currencyFormatRule')
      }
    },
    showOthersCategory: {
      set(value) {
        this.propertyURL.searchParams.set('showOtherItems', value)
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('showOtherItems') === 'true'
      }
    },
    hideClosedCategories: {
      set(value) {
        this.propertyURL.searchParams.set('hideClosedCategories', value)
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('hideClosedCategories') === 'true'
      }
    },
    categories: {
      set(value) {
        value = value.filter(i => i)
        if(!value || !value.length) {
          this.propertyURL.searchParams.delete('category')
        } else {
          this.propertyURL.searchParams.set('category', value.join(','))
        }
        this.value.property = this.propertyURL.toString()
      },
      get() {
        let categories = this.propertyURL.searchParams.get('category')
        if(categories) {
          return categories.split(',')
        } else {
          return []
        }
      }
    },
    excludeTheseCategories: {
      set(value) {
        if(!value || !value.length) {
          this.propertyURL.searchParams.delete('excludeCategories')
        } else {
          this.propertyURL.searchParams.set('excludeCategories', value.join(','))
        }
        this.value.property = this.propertyURL.toString()
      },
      get() {
        let excludeCategories = this.propertyURL.searchParams.get('excludeCategories')
        if(excludeCategories) {
          return excludeCategories.split(',')
        } else {
          return []
        }
      }
    },
    excludeItemsInTheseCategories: {
      set(value) {
        if(!value || !value.length) {
          this.propertyURL.searchParams.delete('exclude')
        } else {
          this.propertyURL.searchParams.set('exclude', value.join(','))
        }
        this.value.property = this.propertyURL.toString()
      },
      get() {
        let exclude = this.propertyURL.searchParams.get('exclude')
        if(exclude) {
          return exclude.split(',')
        } else {
          return []
        }
      }
    },
    includeItemsInTheseCategories: {
      set(value) {
        if(!value || !value.length) {
          this.propertyURL.searchParams.delete('include')
        } else {
          this.propertyURL.searchParams.set('include', value.join(','))
        }
        this.value.property = this.propertyURL.toString()
      },
      get() {
        let include = this.propertyURL.searchParams.get('include')
        if(include) {
          return include.split(',')
        } else {
          return []
        }
      }
    },
    disableVariationSelection: {
      set(value) {
        this.propertyURL.searchParams.set('disableVariationSelection', value)
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('disableVariationSelection') === 'true'
      }
    },
    singlePageVariations: {
      set(value) {
        this.propertyURL.searchParams.set('singlePageVariations', value)
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('singlePageVariations') === 'true'
      }
    },
    primaryColor: {
      set(value) {
        if(value) {
          value = value.slice(1)
          this.propertyURL.searchParams.set('primaryColor', value)
        } else {
          this.propertyURL.searchParams.delete('primaryColor')
        }
        this.value.property = this.propertyURL.toString()
      },
      get() {
        let primary = this.propertyURL.searchParams.get('primaryColor')
        if(primary) {
          return '#'+primary
        } else {
          return null
        }
      }
    },
    accentColor: {
      set(value) {
        if(value) {
          value = value.slice(1)
          this.propertyURL.searchParams.set('accentColor', value)
        } else {
          this.propertyURL.searchParams.delete('accentColor')
        }
        this.value.property = this.propertyURL.toString()
      },
      get() {
        let accent = this.propertyURL.searchParams.get('accentColor')
        if(accent) {
          return '#'+accent
        } else {
          return null
        }
      }
    },
    defaultLanguage: {
      set(value) {
        if(value) {
          this.propertyURL.searchParams.set('language', value)
        } else {
          this.propertyURL.searchParams.delete('language')
        }
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('language')
      }
    },
    limitItemCategoryOpeningHourCheck: {
      set(value) {
        if(value === false) {
          this.propertyURL.searchParams.set('limitCategoryOpeningHoursCheck', value)
        } else {
          this.propertyURL.searchParams.delete('limitCategoryOpeningHoursCheck')
        }
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('limitCategoryOpeningHoursCheck') !== 'false'
      }
    },
    showAddGuestsToTable: {
      set(value) {
        if(value === false) {
          this.propertyURL.searchParams.set('showAddGuests', value)
        } else {
          this.propertyURL.searchParams.delete('showAddGuests')
        }
        this.value.property = this.propertyURL.toString()
      },
      get() {
        return this.propertyURL.searchParams.get('showAddGuests') !== 'false'
      }
    },
    roomServiceTakeawayText: {
      get() {
        return this.propertyURL.searchParams.get('rst') === 'true'
      },
      set(value) {
        this.propertyURL.searchParams.delete('rst')
        if(value === true) {
          this.propertyURL.searchParams.set('rst', value)
        }

        this.value.property = this.propertyURL.toString()
      }
    },
    hideBlockedItems: {
      get() {
        return this.propertyURL.searchParams.get('hideBlockedItems') === 'true'
      },
      set(value) {
        this.propertyURL.searchParams.delete('hideBlockedItems')
        if(value === true) {
          this.propertyURL.searchParams.set('hideBlockedItems', value)
        }
        this.value.property = this.propertyURL.toString()
      }
    },
    languages() {
      return [
        {
          text: this.$t('geoBased'),
          value: null
        },
        {
          text: this.$t('icelandic') ,
          value: 'is'
        },
        {
          text: this.$t('english'),
          value: 'en'
        },
				{
          text: this.$t('swedish'),
          value: 'se'
        },
				{
          text: this.$t('danish'),
          value: 'dk'
        }
      ]
    },
    paymentMethodInstances() {
      if(typeof this.channel !== 'undefined' && this.channel !== null) {
        const foundChannel = this.channels.find(c => c && c.uuid === this.channel)
        if(foundChannel) {
          if(Array.isArray(foundChannel.acceptedPaymentMethodInstances) && foundChannel.acceptedPaymentMethodInstances.filter(notNull => notNull).length > 0) {
            return this.$store.state.paymentMethodInstances.filter(instance => instance && foundChannel.acceptedPaymentMethodInstances.some(accepted => accepted && accepted.uuid === instance.uuid))
          }
        }
      }
      return this.$store.state.paymentMethodInstances.filter(instance => instance.namespace === 'valitor_greidslugatt' || instance.namespace === 'borgun_rpg' || instance.namespace === 'invoice' || instance.namespace === 'valitor_pay')
    },
    channels() {
      return this.$store.state.channels
    },
    etags() {

      if(this.op === 'create') {
        // Filter out etags that have no description - this is because service was asked not to use those and they don't want to use them.
        // https://linear.app/noona/issue/DEV-13893/creating-etags
        // To re-use them we can either remove this filter or clear the description in Mongo.
        return this.$store.state.unclaimedEtags.filter(etag => !etag.description)
      }

      return this.$store.state.etags
    },
    organizationUUID() {
      return this.$store.state.organization.uuid
    }
  },
  beforeMount() {
    this.op = this.value.tagID === null ? 'create' : 'update'
    this.originalURL = this.value.property
  },
  mounted() {
    this.getReservationWidgets()
  }
}
</script>
